






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: "Link",
    props: {
        href: { type: String },
        track: { type: Function }
    },
    setup(props){
        const trackFn = e => {
            props.track(e)
        }
        return { trackFn}
    }
})
